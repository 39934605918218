const TextOptions =[
     {
        name: 'font-size',
        value: 10,
        range: {
          min: 10,
          max: 50
        },
        unit: 'px'
      },
      {
        name: 'positionx',
        value: 50,
        range: {
          min: -100,
          max: 100
        },
        unit: '%'
      },
      {
        name: 'positiony',
        value: 50,
        range: {
          min: -100,
          max: 100
        },
        unit: '%'
      },
      {
        name: 'rotation',
        value: 0,
        range: {
          min: -180,
          max: 180
        },
        unit: 'deg'
      }
]

export default TextOptions