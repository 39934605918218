import React,{Fragment} from 'react'
import Select from 'react-select'

function Text({onChangeText,onClickTextOption,onChangeColor,optionsText,onChangeFilterTextStyle,onChangePolice}){
    
    const police=[

        {label:'Square Peg',value:'Square Peg'},
        {label:'Roboto',value:'Roboto'},
        {label:'Oswald',value:'Oswald'},
    ]

    return <Fragment>
        <div className="d-flex rangeContent flex-column justify-content-center">
            <div className="d-flex justify-content-between">
                <input className="form-control w-75 m-1"  type="text" placeholder="Add your text" onChange={onChangeText} />
                <Select  options={police} onChange ={onChangePolice}  className="m-1"/>
                <input type="color" className="form-control form-control-color w-25 m-1" onChange={onChangeColor}  id="textColor" />
            </div>
            <div className="d-flex justify-content-center mt-2">
                <input className="slider d-inline"  type="range"
                    id={optionsText.id}   
                    min={optionsText.min}
                    max={optionsText.max}
                    value={optionsText.value} 
                    onChange ={onChangeFilterTextStyle} 
                />
            </div>
        </div>
        <div className="d-flex filtre_options mt-3 justify-content-center">
            <a href="#" id="font"       onClick={onClickTextOption}>Font-size</a>
            <a href="#" id="positionX"  onClick={onClickTextOption}>Left to Right</a>
            <a href="#" id= "positionY" onClick={onClickTextOption}>Top to Bottom</a>
            <a href="#" id="rotation"   onClick={onClickTextOption}>Rotation</a>
        </div>
    </Fragment>

}

export default Text