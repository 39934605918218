import React,{ Fragment } from "react";

function Tools({onclickTool}){
    
    return <Fragment>
             <div className="p-2 d-flex flex-column">
                <a href="#"  id="filter" className="options-btn m-2 text-center"   onClick ={onclickTool}>  <span className="text-tool d-block"><i className=" d-block bi bi-sliders"></i> Filter</span> </a>
                <a href="#"  id="text"   className="options-btn  m-2 text-center"  onClick ={onclickTool}> <i className="bi bi-pencil-square d-block"></i><span className="text-tool d-block">Text</span> </a>
                <a href="#"  id="border" className="options-btn m-2 text-center"   onClick ={onclickTool}>   <i className="bi bi-image d-block"></i><span className="text-tool d-block">Border</span> </a>
                <a href="#"  id="flip" className="options-btn m-2 text-center"   onClick ={onclickTool}>   <i className="bi bi-arrow-repeat d-block"></i><span className="text-tool d-block">Filp</span> </a>
            </div>
    </Fragment>
}

export default Tools