import React,{useState,Fragment,useRef,useEffect} from "react";

function PreviewImage( {imageFilter,textFilter,optionsTextFilter,police,bordure,bordertool,imageSrc,flip} ){
      const [base64url, setBase64url] = useState({})
    const [image, setImage] = useState({})
    const [sizepreview, setSizepreview] = useState({})
    
    const ref = useRef()
    
    const getImageStyle = ()=>{

          const filters = imageFilter.map(option => {
            return `${option.property}(${option.value}${option.unit})`
          })
      
          return { 
            filter: filters.join(' '), 
          }
    
    }

    const handleChangeImage = (e) =>{
           
            const src = e.target.src
          
           const img = new Image();
            img.src = src;
            img.onload = () => {
                  setImage({
                  url: src,
                  height: img.height+'px',
                  width: img.width+'px'
                  });
            };
            setSizepreview({
                  width: ref.current.clientWidth,
                  height: ref.current.clientHeight   
            })
     }

      
   
    return (<Fragment>
          <div className="p-2 d-flex flex-grow-1 align-items-center justify-content-center" >
                  <div id="preview" className="contentpreview text-center ">
                    <img src={imageSrc.url}  height="100%"
                     id='image'
                     style={getImageStyle()}
                     onLoad ={handleChangeImage}
                     ref={ref}
                     className={flip.classe}
                    />

                     <div  style={{position:'absolute',left: parseInt(textFilter[1].value)+'%',top: parseInt(textFilter[2].value)+'%',transform:'rotate('+textFilter[3].value+'deg)',transition:'transform 1s'}} >
                            <span style={{color: optionsTextFilter.color, fontSize: parseInt(textFilter[0].value), fontFamily: police.family, fontWeight:police.weigth }}>{optionsTextFilter.text}</span>
                    </div>
                    {(bordertool.border) ? 
                              <div style={{
                                    position:'absolute',
                                    top:0,
                                    left:0,
                                    width:sizepreview.width,
                                    height:sizepreview.height,
                                    border: `${bordure.color} ${bordure.name} ${bordure.value}${bordure.unit}`
                              }}>  
                              </div>
                    :''}

                   {(bordertool.borderImage && bordure.url) ? 
                        <div className="border"> 
                              <img src={bordure.url} width={sizepreview.width} height={sizepreview.height} />
                        </div>
                    :''}
                   
                  </div>
                  
          </div>
         
          <div id="real">
                    <img src={imageSrc.url}
                     style={getImageStyle()}
                     onLoad ={handleChangeImage}
                     className={flip.classe}
                    />

                     <div  style={{position:'absolute',left: parseInt(textFilter[1].value)+'%',top: parseInt(textFilter[2].value)+'%',transform:'rotate('+textFilter[3].value+'deg)',transition:'transform 1s'}} >
                            <span style={{color: optionsTextFilter.color, fontSize: parseInt(textFilter[0].value), fontFamily: police.family, fontWeight:police.weigth }}>{optionsTextFilter.text}</span>
                    </div>
                    {(bordertool.border) ? 
                              <div style={{
                                    position:'absolute',
                                    top:0,
                                    left:0,
                                    width:image.width,
                                    height:image.height,
                                    border: `${bordure.color} ${bordure.name} ${bordure.value}${bordure.unit}`
                              }}>  
                              </div>
                    :''}

                   {(bordertool.borderImage && bordure.url) ? 
                        <div className="border"> 
                              <img src={bordure.url} width={image.width} height={image.height} />
                        </div>
                    :''}  
           </div>
    </Fragment>)
}

export default PreviewImage 