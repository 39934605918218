import React,{useState,Fragment} from 'react'

function Flip({onClickFlip}){

    return <Fragment>   
   
        <div className="d-flex filtre_options  justify-content-center">
            <a href="#" id="flipright" onClick={onClickFlip}>Flip Right</a>
            <a href="#" id="fliptop"   onClick={onClickFlip}>Flip Top</a>
            <a href="#" id="flipleft"   onClick={onClickFlip}>Initial</a>
            
        </div>

    </Fragment>

}
export default Flip