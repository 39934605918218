import React,{Fragment} from 'react'
import Default_options from '../Options'

function Filter({optionsfilter,onChangeFilterStyle, onClickFilter}){
  
    return <Fragment>   
        <div className="d-flex rangeContent justify-content-center ">
            <input className="slider d-inline"  type="range" 
                id={optionsfilter.id}   
                min={optionsfilter.min}
                max={optionsfilter.max}
                value={optionsfilter.value} 
                onChange ={onChangeFilterStyle}
            />
        </div>
        <div className="d-flex filtre_options mt-3 justify-content-center">
            <a href="#" id="brightness" onClick={onClickFilter}>Brightness</a>
            <a href="#" id="contrast"   onClick={onClickFilter}>Contrast</a>
            <a href="#" id="saturation" onClick={onClickFilter}>Saturation</a>
            <a href="#" id="Grayscale"  onClick={onClickFilter}>Grayscale</a>
            <a href="#" id="sepia"      onClick={onClickFilter}>Sepia</a>
            <a href="#" id="hue"        onClick={onClickFilter}>Hue Rotate</a>
            <a href="#" id="blur"       onClick={onClickFilter}>Blur</a>
        </div>
    </Fragment>

}
export default Filter