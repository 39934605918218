import React, { useState, useEffect, useCallback, useRef } from 'react'

import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './App.css'

import Tool from './components/Tools'
import PreviewImage from './components/PreviewImage'
import Filter from './components/Filter'
import Text from './components/Text'
import Border from './components/Border'
import Flip from './components/Flip'


import Default_options from './Options'
import TextOptions from './TextOptions'
import BorderOptions from './BorderOptions'


import html2canvas from 'html2canvas'
import * as htmlToImage from 'html-to-image';
import downloadjs from 'downloadjs'



//import border1 from "./img/border/border1.png";

function App() {


  const inputfile = useRef()

  const [imageSrc, setImageSrc] = useState({
    url: 'https://cdn.pixabay.com/photo/2022/07/13/22/27/butterfly-7320158_1280.jpg'
  })
  // Current option text filter
  const [optionsTextFilter, setOptionsFiltertext] = useState({})

  // style filter image option input
  const [stylefilter, setStylefilter] = useState(Default_options)

  // style filter text option
  const [styletextfilter, setStyletextfilter] = useState(TextOptions)

  // style filter text option
  const [borderstyle, setBordurestyle] = useState(BorderOptions)

  const [borderid, setBorderid] = useState({ id: 0 })

  // flip image option
  const [flipImage,setFlipImage] = useState({})

  // bordure image
  const [bordure, setBordure] = useState({

    id: borderid.id,
    max: BorderOptions[borderid.id].range.max,
    min: BorderOptions[borderid.id].range.min,
    value: BorderOptions[borderid.id].value,
    unit: BorderOptions[borderid.id].unit,
    name: BorderOptions[borderid.id].name,
    color:'#000000',
    url:''
  })

  // font family
  const [police, setPolice] = useState({
    family: 'Roboto',
    weight: 'normal'
  })
  // Navigation sidebar boolean
  const [buttontool, setButtontool] = useState({
    filter: true,
    text: false,
    border: false,
  })
  const [bordertool, setBordertool] = useState({})

  // Option text input
  const [optionsText, setOptionsText] = useState({
    id: 0,
    max: TextOptions[0].range.max,
    min: TextOptions[0].range.min,
    value: TextOptions[0].value,
    unit: TextOptions[0].unit

  })

  const [optionsfilter, setOptionsfilter] = useState({

    max: Default_options[0].range.max,
    min: Default_options[0].range.min,
    value: Default_options[0].value,
    id: 0,
    unit: Default_options[0].unit

  })

  useEffect(() => {

    setOptionsFiltertext({
      text: '',
      color: '#000000',
      sizeFont: 28,
      positionY: 50,
      positionX: 45,
    })
    setBordertool({
      border: true,
      borderImage: false,
    })

  }, [])

  // Sidebar navigation Tool
  const onclickTool = (e) => {

    var button = e.currentTarget.id
    switch (button) {
      case 'filter':
        setButtontool({ filter: true, text: false, border: false })
        break
      case 'text':
        setButtontool({ filter: false, text: true, border: false })
        break
      case 'border':
        setButtontool({ filter: false, text: false, border: true })
        break
      case'flip':
        setButtontool({filter:false,text:false,border:false,flip:true})
        break

    }
  }

  // navigation filter options
  const onClickFilter = (e) => {

    switch (e.target.id) {

      case 'brightness':
        setOptionsfilter({
          max: Default_options[0].range.max,
          min: Default_options[0].range.min,
          value: Default_options[0].value,
          id: 0,
          unit: Default_options[0].unit
        })
        break

      case 'contrast':
        setOptionsfilter({
          max: Default_options[1].range.max,
          min: Default_options[1].range.min,
          value: Default_options[1].value,
          id: 1,
          unit: Default_options[1].unit
        })
        break

      case 'saturation':
        setOptionsfilter({
          max: Default_options[2].range.max,
          min: Default_options[2].range.min,
          value: Default_options[2].value,
          id: 2,
          unit: Default_options[2].unit,
        })
        break

      case 'saturation':
        setOptionsfilter({
          max: Default_options[3].range.max,
          min: Default_options[3].range.min,
          value: Default_options[3].value,
          id: 3,
          unit: Default_options[3].unit,
        })
        break

      case 'sepia':
        setOptionsfilter({
          max: Default_options[4].range.max,
          min: Default_options[4].range.min,
          value: Default_options[4].value,
          id: 4,
          unit: Default_options[4].unit
        })
        break

      case 'hue':
        setOptionsfilter({
          max: Default_options[5].range.max,
          min: Default_options[5].range.min,
          value: Default_options[5].value,
          id: 5,
          unit: Default_options[5].unit
        })
        break

      case 'blur':
        setOptionsfilter({
          max: Default_options[6].range.max,
          min: Default_options[6].range.min,
          value: Default_options[6].value,
          id: 6,
          unit: Default_options[6].unit
        })
        break

      default:
        setOptionsfilter({
          max: Default_options[0].range.max,
          min: Default_options[0].range.min,
          value: Default_options[0].value,
          id: 0,
          unit: Default_options[0].unit
        })

    }

  }

  // Filter Image
  const onChangeFilterStyle = (e) => {

    const value = e.target.value
    const id = e.target.id

    setOptionsfilter({ ...optionsfilter, value: value })
    const updateOptions = stylefilter.map((option, key) => {
      if (key === parseInt(id)) {
        return { ...option, value: value }
      }
      return option
    })
    setStylefilter(updateOptions)

  }

  //Change Text input
  const onChangeText = (e) => {

    const text = e.target.value
    setOptionsFiltertext({ ...optionsTextFilter, 'text': text })

  }

  //Change color Text
  const onChangeColor = (e) => {
    const color = e.target.value
    switch (e.target.id) {
      case 'borderColor':
        setBordure({ ...bordure, 'color': color })
        break
      case 'textColor':
        setOptionsFiltertext({ ...optionsTextFilter, 'color': color })
        break
    }
    /* */


  }

  // Fontsize - Rotation - Position Text
  const onClickTextOption = (e) => {

    const button = e.target.id

    switch (button) {
      case 'font':

        setOptionsText({
          max: TextOptions[0].range.max,
          min: TextOptions[0].range.min,
          value: TextOptions[0].value,
          id: 0,
          unit: TextOptions[0].unit
        })

        break

      case 'positionX':

        setOptionsText({
          max: TextOptions[1].range.max,
          min: TextOptions[1].range.min,
          value: TextOptions[1].value,
          id: 1,
          unit: TextOptions[1].unit
        })

        break
      case 'positionY':

        setOptionsText({
          max: TextOptions[2].range.max,
          min: TextOptions[2].range.min,
          value: TextOptions[2].value,
          id: 2,
          unit: TextOptions[2].unit
        })

        break

      case 'rotation':

        setOptionsText({
          max: TextOptions[3].range.max,
          min: TextOptions[3].range.min,
          value: TextOptions[3].value,
          id: 3,
          unit: TextOptions[3].unit
        })

        break
    }
  }
  // Change Text style
  const onChangeFilterTextStyle = (e) => {

    const value = e.target.value
    const id = e.target.id

    setOptionsText({ ...optionsText, value: value })
    const updateOptions = styletextfilter.map((option, key) => {
      if (key === parseInt(id)) {
        return { ...option, value: value }
      }
      return option
    })
    setStyletextfilter(updateOptions)

  }

  //Change police
  const onChangePolice = (selected) => {

    setPolice({ ...police, family: selected.value })

  }

  //Change Border menu options
  const onClickBorder = (e) => {

    var button = e.currentTarget.id
    switch (button) {
      case 'border':
        setBordertool({ border: true, borderImage: false })
        break
      case 'borderImage':
        setBordertool({ border: false, borderImage: true })
        break
    }

  }


  // Change Thick border
  const onChangeThick = (e) => {
    const value = e.target.value
    setBordure({ ...bordure, value: value })
  }

  const onChangeBorderStyle = (selected) => {
    setBordure({ ...bordure, name: selected.value })
  }


  const handleClickImageButton = (e) => {
    inputfile.current.click()
  }

 /*const validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }*/
  const getImage=(url) =>{

    const formData = new FormData();
    formData.append('url', url);
    const uriServer = 'https://image-editor.page42.org/php/server.php'
    
    const server = fetch(uriServer,{
          method:'POST',
          body: formData
          })
          .then(res => res.json())
          .then(
          (result) => {
            setImageSrc({ ...imageSrc, url: result.src })
          },
          (error) => {
          console.log(error)
          }
    )
  
}
  const handelChangeImage = (e) => {
  
    switch (e.target.id) {
      case 'externe':
      

            const allconfauto = e.target.value.split('?')
            const value = allconfauto[0]
            const searchParams = new URLSearchParams(allconfauto[1])
            let color='' ,
            borderStyle='', 
            bordureSize=0,
            texte='',
            TexteSize=10,
            tpositionX = 50,
            tpositionY= 50,
            trotation=0,
            policeCarc='',
            tColor='',
            bg=100,
            ct=100,
            sat=100,
            gr=0,
            hr=0,
            sp=0,
            bl=0

            if(searchParams.has('border')){

              borderStyle = searchParams.get('border')

            }
            if(searchParams.has('bSize')){

              bordureSize = searchParams.get('bSize')

            }
            if(searchParams.has('bColor')){

              color =searchParams.get('bColor')

            }
            if(searchParams.has('texte')){
              let TextToReplace = searchParams.get('texte')
              texte = TextToReplace.replace('-',' ',texte)
             
            }

            if(searchParams.has('tSize')){

              TexteSize= searchParams.get('tSize')
    
            }

            if(searchParams.has('tpositionX')){

              tpositionX = searchParams.get('tpositionX')

            }
            if(searchParams.has('tpositionY')){

              tpositionY= searchParams.get('tpositionY')

            }

            if(searchParams.has('trotation')){

              trotation= searchParams.get('trotation')

            }

            if(searchParams.has('bg')){

              bg= searchParams.get('bg')

            }
            if(searchParams.has('ct')){

              ct= searchParams.get('ct')

            }
            if(searchParams.has('sat')){

              sat= searchParams.get('sat')

            }
            if(searchParams.has('gr')){

              gr= searchParams.get('gr')

            }
            if(searchParams.has('sp')){

              sp= searchParams.get('sp')

            }
            if(searchParams.has('bl')){

              bl= searchParams.get('bl')

            }
            if(searchParams.has('hr')){

              hr= searchParams.get('hr')

            }
            if(searchParams.has('police')){

              let policeToReplace = searchParams.get('police')
              policeCarc = policeToReplace.replace('-',' ',texte)

            }
            if(searchParams.has('tColor')){

              tColor = searchParams.get('tColor')

            }

            setPolice({...police,family:policeCarc})
            
            setBordure({...bordure,
              name:(borderStyle.length > 0) ? borderStyle  :'solid',
              value:(bordureSize> 0) ? bordureSize : 0,
              color:(color.length > 0) ? color: '#000'
            })

            setOptionsFiltertext({ ...optionsTextFilter,
               'text': texte,
               'color':tColor
             })

            const updateOptions = styletextfilter.map((option, key) => {

              if (key === 0) {
                return { ...option, value: TexteSize }
              }else if(key === 1){
                return { ...option, value: tpositionX }
              }else if(key === 2){
                return { ...option, value: tpositionY }
              }else if(key === 3){
                return { ...option, value: trotation }
              }
              return option

            })

            setOptionsfilter({ ...optionsfilter, value: value })

            const updateOptionsF = stylefilter.map((option, key) => {

              if (key === 0) {

                return { ...option, value: bg }

              }else if(key === 1){

                return { ...option, value: ct }

              }else if(key === 2){

                return { ...option, value: sat }

              }else if(key === 3){

                return { ...option, value: gr }

              }else if(key === 4){

                return { ...option, value: sp }

              }else if(key === 5){

                return { ...option, value: hr }

              }else if(key === 6){

                return { ...option, value: bl }
              }

              return option
            })

            setStylefilter(updateOptionsF)

            setStyletextfilter(updateOptions)
            
            getImage(value)

        break

      case 'upload':

        const url = URL.createObjectURL(e.target.files[0])
        console.log(url)
        setImageSrc({ ...imageSrc, url: url, })

        break

    }

  }
  
  const onClickFlip =(e) =>{
    switch(e.target.id){
       case 'flipright':
         setFlipImage({...flipImage,classe:'flip-right'})
         break
       case 'flipleft':
         setFlipImage({...flipImage,classe:'flip-left'})
         break
       case 'fliptop':
           setFlipImage({...flipImage,classe:'flip-top'})
           break
       case 'flipbottom':
           setFlipImage({...flipImage,classe:'flip-bottom'})
           break
    }
 }


  

  const onClickDownload = useCallback(async () => {


  
    const canvas = await html2canvas(document.getElementById('real'),{
      useCORS: true,

    });

    const dataURL = canvas.toDataURL('image/png');
    //console.log(dataURL)
    downloadjs(dataURL, 'picture-'+Date.now()+'.png', 'image/png')

  }, [])



  const onChangeSelectBorder = (selected) => {

    const url = (selected.value != 'Noborder') ? require('./img/border/' + selected.value + '.png') : ''
    setBordure({ ...bordure, url: url })

  }

  return (
    <div className="container d-flex flex-column align-items-center mt-5">
      <div style={{ position: 'absolute', left: 0 }}>
        <NotificationContainer />
      </div>
      <div className="row">
        <div className="d-flex flex-column align-items-center justify-content-center containheight">

          <div className="d-flex options justify-content-end align-items-center w-100">
            <input type="text" name="img-url" placeholder="Paste your external image url or add local image on click button" className="form-control w-75" onBlur={handelChangeImage} id="externe" />
            <button className="btn btn-sm btn-primary text-center m-2" onClick={handleClickImageButton} >+</button>
            <input type="file" ref={inputfile} style={{ display: 'none' }} onChange={handelChangeImage} id="upload" />

          </div>
          <div className="d-flex">
            <Tool onclickTool={onclickTool} />
            <PreviewImage
              imageFilter={stylefilter}
              optionsTextFilter={optionsTextFilter}
              textFilter={styletextfilter} police={police}
              bordure={bordure}
              bordertool={bordertool}
              imageSrc={imageSrc}
              flip={flipImage}
            />
          </div>
          <div className="d-flex flex-row options p-2 w-100 justify-content-between align-items-end">
          <button className="btn btn-sm btn-primary text-center m-2 btn-custom-download" onClick={onClickDownload} >Download</button>
            <div className="d-flex flex-column w-100">
              {
                (buttontool.filter) ? <Filter
                  onChangeFilterStyle={onChangeFilterStyle}
                  onClickFilter={onClickFilter}
                  optionsfilter={optionsfilter}
                />
                  : ''
              }
              {
                (buttontool.text) ? <Text
                  onChangeText={onChangeText}
                  onChangeColor={onChangeColor}
                  onClickTextOption={onClickTextOption}
                  onChangeFilterTextStyle={onChangeFilterTextStyle}
                  onChangePolice={onChangePolice}
                  optionsText={optionsText}
                />
                  : ''
              }
              {
                (buttontool.border) ? <Border
                  borderThick={bordure}
                  borderOptions={bordertool}
                  onClickBorder={onClickBorder}
                  onChangeThick={onChangeThick}
                  onChangeBorderStyle={onChangeBorderStyle}
                  onChangeColor={onChangeColor}
                  onChangeSelectBorder={onChangeSelectBorder}
                />
                  : ''
              }
              {
                (buttontool.flip) ? <Flip
                                        onClickFlip ={onClickFlip}
                                    />
                :''
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App;
