import React,{Fragment} from 'react'
import BorderOptions from './../BorderOptions'
import Select from 'react-select'

function Filter({

    borderThick,onChangeThick,
    onClickBorder,
    borderOptions,
    onChangeColor,
    onChangeBorderStyle,
    onChangeSelectBorder

}){

    const borderStyle=[

        {label:BorderOptions[0].name,value:BorderOptions[0].name},
        {label:BorderOptions[1].name,value:BorderOptions[1].name},
        {label:BorderOptions[2].name,value:BorderOptions[2].name},
    ]

    const options_border=[
        {label:'NoBorder',value:'Noborder'},
        {label:'Border1',value:'border1'},
        {label:'Border2',value:'border2'},
    ]


    return <Fragment>   
        <div className="d-flex rangeContent justify-content-center ">
           {
            (borderOptions.border) ?
                <div className="d-flex justify-content-between w-100">
                    <input className="slider d-inline m-1"  type="range" 
                        id={borderThick.id}   
                        min={borderThick.min}
                        max={borderThick.max}
                        value={borderThick.value} 
                        onChange ={onChangeThick}
                    />
                    <Select  options={borderStyle} onChange ={onChangeBorderStyle}  className="m-1"/>
                    <input type="color" className="form-control form-control-color w-25 m-1"  onChange={onChangeColor} id="borderColor"  />
                </div>
                :''
            }
            {
            (borderOptions.borderImage) ?
                <div className="d-flex justify-content-center w-75">
                    <Select  options={options_border} onChange ={onChangeSelectBorder}  />
                </div>
                :''
            }
        </div>
        <div className="d-flex filtre_options mt-3 justify-content-center">
            <a href="#" id="border" onClick={onClickBorder}>Border</a>
            <a href="#" id="borderImage"   onClick={onClickBorder}>Border image</a>
        </div>
    </Fragment>

}
export default Filter