const BorderOptions =[
    {
       name: 'solid',
       value: 0,
       range: {
         min: 0,
         max: 100
       },
       unit: 'px'
     },
     {
       name: 'dashed',
       value: 0,
       range: {
         min: 0,
         max: 100
       },
       unit: 'px'
     },
     {
       name: 'double',
       value: 0,
       range: {
         min: 0,
         max: 100
       },
       unit: 'px'
     },
     {
       name: 'groove',
       value: 0,
       range: {
         min: 0,
         max: 100
       },
       unit: 'px'
     },
     {
       name: 'ridge',
       value: 0,
       range: {
         min: 0,
         max: 100
       },
       unit: 'px'
     }
]

export default BorderOptions